@use "../../ui-kit/colors.scss" as c;
@use "../../ui-kit/devices.scss";
@import "../../ui-kit/mixins.scss";

.rec-accordion-item {
  background-color: c.$deepest-blue-3;
  font-variant-numeric: lining-nums tabular-nums;
  overflow: visible;
  position: relative;
  z-index: 1;

  .accordion-content {
    &.level-0 {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: c.$deepest-blue-3;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.35);
    }

    &.level-1 {
      &:last-child {
        padding-bottom: 10px;
        padding-top: 8px;
      }
    }
  }

  .accordion-header {
    padding: 12px 10px 14px;
    display: flex;
    gap: 2px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    @include devices.mobile {
      padding: 15px 20px;
    }

    &.level-0,
    &.level-1 {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        height: 1px;
        border-radius: 10px;
        background-color: c.$deepest-blue-4;
        display: block;
      }

      &.level-0:not(.clickable),
      &.level-0:not(:has(+ .accordion-content)) {
        &::after {
          display: none;
        }
      }
    }

    &.clickable {
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }

    .value-container .label {
      font-size: 12px !important;
      line-height: 16px !important;
      font-weight: 500;

      // @include devices.mobile {
      //   font-size: 14px !important;
      //   line-height: 20px !important;
      // }
    }
  }

  .value-container {
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: left;

    &.level-0,
    &.level-1 {
      width: 100%;
      justify-content: space-between;
    }

    .mobile-indicator {
      width: 20px;
      height: 20px;
      opacity: 0.7;
      color: c.$white;
      position: absolute;
      top: 10px;
      right: 15px;

      @include devices.not-mobile {
        display: none;
      }
    }
  }

  .value {
    font-weight: 600;
    @include fs-24;

    &.negative {
      //   color: #ff4d4d;
    }
  }

  .arrow {
    font-size: 20px;
    opacity: 0.7;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.down {
      transform: scale(1.5) translateY(-5px);
    }
  }

  .accordion-header {
    &.level-0 {
      font-weight: 600;
    }
  }

  &.level-0 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.level-1 {
    .accordion-header .value {
      @include fs-16;
    }

    .label {
      font-weight: 600;
    }
  }

  &.level-2 {
    .accordion-header {
      padding: 2px 15px;
      flex-direction: row-reverse;
      align-items: center;

      .value {
        @include fs-12;
      }

      .label {
        font-weight: 400;
      }
    }
  }

  &.mobile {
    .value {
      @include fs-20;
    }

    .label {
      @include fs-14;
    }
  }

  &.in-modal {
    @include devices.mobile {
      .accordion-header {
        cursor: default !important;
        
        &:hover {
          background-color: transparent !important;
        }
      }
      
      .accordion-content {
        &.level-0 {
          position: relative;
          box-shadow: none;
        }
      }
    }
  }
}

.modal-window {

  .icon-wizard {
    z-index: 3;
  }

  .adjusted-net-modal {
    display: flex;
    flex-direction: column;
    max-height: 90vh;

    .modal-inner-header {
      padding: 15px;
      position: sticky;
      top: 0;
      z-index: 2;
      border-bottom: 1px solid c.$deepest-blue-4;
    }

    .modal-inner-body {
      padding: 0;
      overflow-y: auto;
      flex: 1;
    }

    .mobile-accordion-modal {
      width: calc(100vw - 60px);
      height: 100%;
      background-color: c.$deepest-blue-3;
      
      .rec-accordion-item.mobile {
        &.level-0 {
          position: relative;
          box-shadow: none;
        }

        .accordion-content {
          &.level-0 {
            position: relative;
            box-shadow: none;
          }
        }
      }
    }
  }
}

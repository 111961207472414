@use "../ui-kit/colors.scss" as c;
@use "../ui-kit/devices.scss";

.edit-profile-page-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.5rem 1.5rem 0 1.5rem;
  overflow: auto;

  @include devices.desktop {
    padding: 0 1.5rem 0 1.5rem;
  }

  .edit-profile-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    h3 {
      font-family: "Syne";
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 25px;
      color: c.$cool-gray-10;
    }

    .form-group-block {
      .form-label {
        color: c.$deepest-blue-8;
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 0;
      }
    }

    .form-control {
      background: transparent;
      border-radius: 0;
      color: c.$white;
      border: 0;
      padding: 0.3rem 0;
      box-shadow: none;
      border-bottom: 1px solid c.$deepest-blue-4;
    }

    .profile-card-image {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      width: 35%;
      height: 100%;

      @include devices.desktop {
        padding: 115px 0 0 0;
      }

      @include devices.mobile-resolution {
        width: auto;
        height: auto;
        order: 1;
      }

      .card-image-wrapper {
        width: 100%;
        max-width: 300px;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .user-avatar-block {
          display: flex;
          width: 140px;
          height: 140px;
          min-height: 140px;
          justify-content: center;
          align-items: center;
          border: 1px solid c.$deepest-blue-4;

          span {
            padding: 10px;
            color: var(--deepest-blue-8);
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .upload-mock {
          cursor: pointer;
          position: absolute;
          right: 24px;
          top: 3px;
          text-decoration: underline;
          color: black;
        }

        .upload-button-block {
          display: flex;
          max-width: 450px;
          margin: 22px 0 0 0;
          justify-content: space-evenly;
          width: inherit;

          .btn {
            line-height: 40px;
          }

          .trash-button {
            border: none;
            background-color: transparent;
            cursor: pointer;
            transition: 0.2s ease-in-out;
          }
        }
      }
    }

    .submit-request-button {
      width: fit-content;
      align-self: center;
    }

    .edit-profile-request {
      display: flex;
      width: 50%;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 40px;

      .form-label {
        font-size: smaller;
      }

      .request-input {
        max-width: 500px;
      }
    }

    .list-group {
      width: 100%;

      .list-group-item {
        border: none;
        text-align: initial;
        width: 100%;
        border-bottom: 1px solid #cbcbcb;
        cursor: pointer;
      }
    }

    .form-block {
      width: 65%;
      padding: 0;
      height: 100%;
      justify-content: flex-start;
      align-items: flex-start;

      @include devices.mobile-resolution {
        width: 100%;
        height: auto;
      }

      .form-wrapper {
        width: 100%;
        padding: 0;
        max-width: unset;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include devices.desktop {
          width: 75%;
          margin-top: 18px;
        }

        @include devices.desktop-large {
          width: 55%;
        }
      }
    }
  }

  .edit-profile-taskbar {
    align-self: baseline;

    .title {
      text-decoration: underline;
    }
  }

  .no-image-mock {
    width: 100% !important;
    height: 250px;

    &::after {
      border: 3px solid c.$accent-color;
      border-radius: 10px;
    }
  }

  .card {
    align-items: flex-start;
    height: calc(100vh - 100px);
    background: transparent;
    border-radius: 0;
    border: 0;
    flex-direction: row;

    @include devices.mobile-resolution {
      height: auto;
      align-items: center;
      justify-content: flex-start;
      gap: 40px;
    }
  }

  .edit-profile-main-button {
    border-color: var(--new-leaf-green-5);
    padding: 10px 28px;
    color: var(--color-white);

    &:hover {
      background-color: c.$new-leaf-green-5-012;
    }

    &:active {
      background-color: c.$new-leaf-green-5-02;
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

@use "../colors.scss" as c;

.date-input-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 10px;

  .date-label {
      padding-left: 0;
      white-space: nowrap;

      &::before, &::after {
          content: none;
      }
  }

  input[type="date"].form-control {
      background-color: c.$deepest-blue-2;
      height: 48px;
      word-wrap: normal;
      font-size: 1rem;
      padding: 12px 16px;
      border: 1px solid c.$deepest-blue-4;
      color: c.$white !important;
      min-width: 200px;
      padding-right: 40px; // Space for the calendar icon
      position: relative;
      width: 100%; 
      box-sizing: border-box; // Ensure padding is part of width

      &::-webkit-calendar-picker-indicator {
          background: none;
          z-index: 2;
          cursor: pointer;
          width: 100%; // Make the indicator cover the whole input
          height: 100%; // Ensure full height coverage
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0; // Hide the default icon, but make the entire input clickable
      }

      &::before {
          content: "";
          background-image: url("./calendar.svg");
          background-repeat: no-repeat;
          background-position: center;
          width: 25px;
          height: 25px;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;
      }

      &.greyed-field {
        opacity: .5;
        pointer-events: none;
        cursor: inherit;
      }

      &:focus {
          outline: none;
      }
  }

  &:hover {
      cursor: pointer;
  }
}

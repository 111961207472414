@use "ui/ui-kit/colors.scss" as c;
@use "/src/ui/ui-kit/devices.scss";
@import "ui/ui-kit/mixins.scss";

.confirm-modal {
  min-width: 540px;
  
  @include devices.mobile {
    min-width: 92%;
  }

  .current-price-row,
  .change-price-row,
  .title {
    margin-bottom: 23px;
  }

  .left-side {
    width: 30%;
  }

  .type-eod-row {
    > div {
      display: flex;
      justify-content: space-between;
      width: 100%;

      label {
        width: 50%;
      }
    }
  }
}

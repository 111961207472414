@use "../colors.scss" as c;
@use "../../ui-kit/devices.scss";

.popup-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: 12;

  .popup-black-area {
    background-color: rgba(0, 0, 0, 0.367);
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .popup-area {
    background: c.$deepest-blue-2-primary;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 320px;
    max-width: 400px;
    padding: 15px;
    max-height: 300px;
    height: auto;


    @include devices.desktop {
      width: 50vw;
      height: 30vh;
    }

    .popup-message {
      font-size: 1.3rem;
    }

    .popup-buttons {
      display: flex;
      justify-content: center;
      align-self: center;
      gap: 0;
      margin-top: 20px;

      @include devices.desktop {
        margin-top: 0;
        gap: 1em;
      }

      button {
        margin: 0.5em 10px 0;
        width: fit-content;
        color: white;
        background-color: c.$accent-color;
        border: none;
        opacity: 0.8;
        transition: 0.3s ease-in-out;

        @include devices.mobile-resolution {
          font-size: 0.675rem;
        }

        @include devices.desktop {
          margin: 0.5em 0 0;
        }

        &:disabled {
          background-color: grey;
          color: darkgrey;
        }

        &:focus {
          box-shadow: none;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
@use "../ui-kit/colors.scss" as c;
@use "../ui-kit/devices.scss";
@import "/src/ui/ui-kit/mixins.scss";

.statements-wrapper {
  position: relative;
  padding: 18px 1.5rem 1.5rem 1.5rem;
  font-size: 1rem;
  height: 100%;
  width: 100%;
  text-align: left;

  h2 {
    @include fs-20;
    font-family: "Syne";

    @include devices.mobile {
      @include fs-16;
    }
  }

  .statements-list-block {
    overflow: auto;
    height: inherit;
    padding-bottom: 20px;

    .statements-list-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 485px;
      background-color: c.$deepest-blue-2;
      gap: 2px;

      .statements-list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 12px;
        gap: 8px;
        background-color: c.$deepest-blue-3;
        white-space: nowrap;
        @include fs-14;

        @include devices.mobile {
          @include fs-12;
        }

        &:hover {
          background-color: c.$deepest-blue-4;
        }

        .list-item-left {
          font-weight: 500;
          padding-left: 12px;
          width: 100%;
        }

        .list-item-right {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 10px 10px 8px;
          color: c.$deepest-blue-9;
          min-width: 200px;

          img {
            cursor: pointer;
          }

          @include devices.mobile {
            min-width: 180px;
          }
        }
      }
    }
  }


  .no-statements-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include devices.mobile {
      top: -20%;
    }

    .no-statements-message {
      color: c.$deepest-blue-8;
      font-weight: 700;
      font-family: "Syne";
      @include fs-16;
    }
  }
}
@use "../ui-kit/devices.scss";

.change-password-block {
  width: 100%;
  padding: 0;
  max-width: unset;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include devices.desktop {
    width: 75%;
    margin-top: 18px;
  }

  @include devices.desktop-large {
    width: 55%;
  }

  .form-group-block {
    text-align: left;
    position: relative;
    max-width: 400px;
    width: 100%;

    &.not-match {
        input {
            border-bottom-color: var(--red-vivid-6);
        }
    }
  }

  .button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    gap: 10px;

  }

  .not-match-pass-block {
    margin-top: 10px;
    color: var(--red-vivid-7);
    text-align: left;
    font-size: 13px;
  }
}

@use "../ui-kit/colors.scss" as c;
@use "../ui-kit/devices.scss";
@import "/src/ui/ui-kit/mixins.scss";

.resources {
  position: relative;
  padding: 18px 1.5rem 1.5rem 1.5rem;
  font-size: 14px;
  width: 100%;
  text-align: left;
  background-color: c.$deepest-blue-2;

  h3 {
    @include fs-20;
    font-family: "Syne";

    @include devices.mobile {
      @include fs-16;
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.warning {
    @include fs-12;
    color: c.$red-vivid-6;
}
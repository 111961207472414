@use "../ui-kit/colors.scss" as c;
@use "../ui-kit/devices.scss";
@import "/src/ui/ui-kit/mixins.scss";

.default-layout-block {
  display: grid;
  background-color: var(--deepest-blue-2);
  height: 100vh;
  grid-template-columns: 1fr;

  @include devices.desktop {
    grid-template-columns: 48px 1fr;
  }

  .blacked-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.243);
    z-index: 8;
  }

  .main-body-block {
    display: flex;
    padding-top: 100px;
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    background: var(--deepest-blue-2);

    .account-disabled-status {
      color: var(
        --red-vivid-5
      ) !important; // important to overwrite default text color
      font-weight: 600;
    }
    
    .your-reply-needed {
      background-color: var(--red-vivid-5);
      font-weight: bold;
    }
  }

  .toast {
    position: fixed;
    bottom: 2%;
    width: 98%;
    font-size: 1.2rem;
    color: var(--color-white);
    left: 50%;
    transform: translateX(-50%);
    z-index: 12;
  }
}

.expanded-sidebar {
  @include devices.desktop {
    grid-template-columns: 240px 1fr;
  }
}

@use "../colors.scss" as c;

.review-field-block {
  position: relative;
  flex-direction: column;
  padding: 1em 0;
  display: flex;
  gap: 1em;
  line-height: 2;

  .spinner-button {
    position: absolute;
    bottom: 2.3em;
    left: 7em;
  }

  .review-upload-image {
    display: flex;
    flex-direction: row;
    gap: 2em;

    .new-tab-pdf-link {
      position: relative;
      padding: 10px;
      color: black;
      background-color: #fff;
      border-radius: 10px;
    }

    .review-example-image {
      height: 100%;
      position: relative;
      cursor: pointer;

      .uploading-image {
        opacity: 0.5;
      }

      .example-spinner-wrapper {
        position: absolute;
        top: -2.6em;
        left: 4em;
        .spinner {
          margin-top: 0;
        }
      }

      .remove-image-button {
        height: 30px;
        width: 30px;
        position: absolute;
        top: -3em;
        right: 0;
        background-color: transparent;

        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 30px;
          width: 30px;
        }
      }

      .example-image {
        border: 1px solid grey;
        border-radius: 10px;
        max-width: 150px;
        max-height: 150px;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        &:hover {
          background-color: white;
        }
      }

      .info-error-text {
        position: absolute;
        top: 0;
        left: 160px;
        color: #ff000075;
        width: 100%;
        max-width: 150px;
        overflow: hidden;
      }
    }
    label {
      top: 0;
      position: absolute;
      font-weight: bold;
      text-transform: capitalize;
    }
    span {
      position: absolute;
      bottom: 3px;
      font-size: 0.7em;
      font-weight: lighter;
      color: grey;
      line-height: 1;
    }
    button {
      transition: 0.3s ease-in-out;
      &:hover {
        background-color: c.$accent-color-semi-transparent;
      }
    }
  }

  .review-field-item-block {
    position: relative;
    border: 2px solid grey;
    padding: 1.3em 1em;
    border-radius: 10px;

    .item-comment-block {
      font-size: 0.8em;
      color: c.$error-light-red-color;
      margin: 1em 0;

      .item-comment {
        word-wrap: break-word;
        line-height: 1.5;
        margin: .5em 0;
      }
    }
    .review-default-field-item {
      display: flex;
      flex-direction: column;

      .not-valid-form {
        color: c.$error-light-red-color;
      }

      label {
        position: absolute;
        top: 5px;
        font-weight: bold;
        font-size: .9em;
        text-transform: capitalize;
      }
      input {
        padding: 5px;
        font-size: .8em;
      }
    }
  }

  button {
    max-width: min-content;
    border: none;
    background-color: c.$accent-color;
    padding: 5px 15px;
    color: white;
    font-weight: bold;
    transition: 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      color: black;
    }
    &:disabled {
      background-color: grey;
      &:hover {
        color: white;
      }
    }
  }
  .fullpreview {
    display: flex;
    align-items: center;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);

    z-index: 1081;
    justify-content: center;
    text-align: center;

    cursor: zoom-out;

    .image {
      object-fit: contain;
      width: auto !important;
      height: auto;
      max-width: 70vw !important;
      max-height: 70vh;
      cursor: zoom-out;
      border: none;
      margin: 0;
    }

    .image-info-block {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0 5%;
      padding: 15px;
      max-width: 1000px;
      overflow-x: auto;
      cursor: zoom-out;
      background-color: white;
      border-radius: 10px;

      .image {
        width: 100%;
        max-height: 386px;
        max-width: fit-content;
      }

      .info {
        margin-left: 20px;
      }
    }
  }
  .done-bg {
    background-color: c.$accent-color-very-transparent;
  }
}

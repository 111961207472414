@use "../colors.scss" as c;

.attachment-download-page-wrapper {
  text-align: initial;
  width: fit-content;
  cursor: pointer;
  overflow: auto;
  max-height: calc(100vh - 100px);
  display: flex;
  gap: 12px;
  padding: 12px 16px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;

  img {
    height: 100%;
    transition: 0.3s ease-in-out;
    max-height: 30px;
    margin: 0 !important;
  }

  span {
    min-width: 170px;
  }

  &:hover {
    text-decoration: underline;
    border: 1px solid rgba(c.$accent-color, 0.2);
    border-radius: 4px;
    transition: 0.6s color;
  }
}
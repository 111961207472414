@use "./ui/ui-kit/colors.scss" as c;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;900&family=Raleway&display=swap');

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Montserrat', 'Raleway', 'Hind', sans-serif; 
 /* font-family: 'Helvetica Neue', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.greyed-text {
  color: c.$deepest-blue-8;
}

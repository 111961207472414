@use "ui/ui-kit/colors.scss" as c;
@use "/src/ui/ui-kit/devices.scss";
@import "ui/ui-kit/mixins.scss";

.cabinet-modals {
  &.chat-modal {
    width: 1200px;
    max-width: 1200px;
    max-height: 1000px;
    height: calc(100% - 80px);
    margin-top: 40px;
    margin-bottom: 40px;

    .icon-wizard-x {
      color: c.$black;
      top: 10px;
      right: 19px;
    }

    .modal-inner-header {
      padding: 11px 100px 11px 13px;
      @include fs-16;
      font-weight: 600;

      p {
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        div {
          display: inline-block;
        }
      }
    }

    .modal-inner-body {
      padding: 13px 40px;
      height: calc(100% - 44px);
      
      @include devices.mobile {
        padding: 10px;
      }
    }

    .modal-item-info {
      padding: 0 12px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @include devices.mobile {
        flex-direction: column-reverse;
      }

      table {
        width: 50%;
        
        @include devices.mobile {
          margin-top: 10px;
          width: 100%;
          @include fs-12;
        }

        th {
          width: 33.3%;
          font-weight: 500;
          padding-bottom: 4px;
        }
      }

      >button {
        margin-top: 7px;
        display: flex;

        >span {

          &:nth-child(1) {
            order: 1;
            letter-spacing: 0.96px;
          }

          &:nth-child(2) {
            margin: 0 10px 0 0;
            order: 0;
          }
        }

        &.cancel-request {
          >span {
            &:nth-child(2) {
              i {
                color: c.$red-vivid-6;
              }
            }
          }
        }
      }
    }

    .chat-outer-container {
      height: inherit;
      padding-top: 24px;
      padding-bottom: 13px;
    }

    .loader {
      position: absolute;
      top: 44px;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: rgba(0, 0, 0, 0.8);
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.modal-window {
  .chat-overlay {
    background-color: c.$deepest-blue-2;
    opacity: 50%;
  }
}
@use "/src/ui/ui-kit/colors.scss" as c;
@use "/src/ui/ui-kit/devices.scss";
@import "/src/ui/ui-kit/mixins.scss";

.taskbar-block {
  position: fixed;
  top: 0;
  width: -webkit-fill-available;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  background-color: var(--deepest-blue-2);
  border-top: 8px solid c.$vibrant-green-7;

  .taskbar-container {
    display: flex;
    height: 92px;
    width: 100%;
    padding: 0px 20px 12px 20px;
    justify-content: space-between;
    align-items: center;

    .menu-icon {
      cursor: pointer;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);

      @include devices.desktop {
        display: none;
      }
    }
  }

  .taskbar-title-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    white-space: nowrap;

    @include devices.mobile {
      margin-left: 40px;
    }

    @include devices.tablet {
      margin-left: 40px;
    }

    span {
      color: var(--deepest-blue-9);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      @include devices.mobile {
        @include fs-8;
      }
    }
  }

  .support-button {
    position: relative;
    height: 45px;
    width: 45px;
    background-color: c.$accent-color;
    border: c.$accent-color solid 1px;

    border-radius: 50%;
    text-align: right;
    vertical-align: bottom;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
      width: 240px;
    }

    img {
      position: absolute;
      top: 4px;
      right: 4px;
      height: 35px;
      fill: white;
      filter: invert(100%) brightness(90%);

      // filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
      &:hover {
        filter: invert(100%) brightness(100%);
        transition: 0.2s;
      }
    }
  }

  .taskbar-collapse-block {
    position: relative;
    display: flex;
    padding: 2px 10px 2px 2px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 18px 0px 0px 18px;
    cursor: pointer;
    
    @include devices.mobile {
      border-radius: 18px;
      padding: 3px;
    }

    .taskbar-collapse-block-item {
      display: flex;
      align-items: center;
      gap: 10px;
      @include fs-20;
      font-family: "Syne", sans-serif;

      @include devices.mobile {
        span {
          display: none;
        }
      }
    }

    .menu-block {
      position: absolute;
      bottom: -40px;
      right: 0;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: var(--deepest-blue-3);
    }

    .taskbar-avatar {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      @include fs-14;

      .dropdown-menu-icon,
      .dropdown-menu-icon-inverted {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -20px;
      }

      .dropdown-menu-icon-inverted {
        transform: translateY(-50%) rotateZ(180deg);
      }
    }

    .taskbar-avatar-picture {
      height: 100%;
      width: 100%;
      border-radius: 100px;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .taskbar-avatar-letters {
      background: linear-gradient(
        159deg,
        #419bf6 0%,
        #44a0f1 13.97%,
        #4caee1 31.62%,
        #58c4c7 51.17%,
        #67deaa 68.61%
      );
      border-radius: 20px;
      color: c.$deepest-blue-2;
      font-weight: 500;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .navlink-wrapper {
      position: relative;
      min-height: 60px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 10px;
      transition: 0.45s cubic-bezier(0.25, 1, 0.2, 1);
      text-decoration: none;
      color: rgb(121, 121, 121);
      font-size: 0.9em;

      a {
        text-decoration: none;
        color: rgb(121, 121, 121);
        font-size: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        a {
          color: white;
          padding: 5px;
        }

        background-color: #555555;
        color: white;
        cursor: pointer;
        border-radius: 10px;
        transition: 0.75s cubic-bezier(0.25, 1, 0.2, 1);
        padding-left: 1px;
      }
    }

    .personal-icons-block {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 10px;

      .dropdown-menu-icon,
      .dropdown-menu-icon-inverted {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -20px;
      }

      .dropdown-menu-icon-inverted {
        transform: translateY(-50%) rotateZ(180deg);
      }

      .dropdown {
        margin-left: 10px;
      }

      .personal-icon-dropdown-menu {
        background-color: c.$deepest-blue-4;
        border-radius: 0;
        width: 100%;
        transform: translate3d(10px, 72px, 0px) !important;

        @include devices.tablet {
          transform: translate3d(10px, 63px, 0px) !important;
        }

        @include devices.mobile {
          transform: translate3d(10px, 63px, 0px) !important;
        }

        .dropdown-item {
          @include fs-14;
          display: flex;
          align-items: center;
          height: 48px;
          font-weight: 500;
          color: white;

          img {
            margin-right: 8px;
          }

          &:hover {
            background-color: c.$deepest-blue-5;
          }
        }
      }

      .taskbar-fullname-block {
        display: flex;

        @include devices.mobile {
          display: none;
        }
      }

      span {
        margin: 0 5px;

        &:nth-child(2) {
          color: c.$accent-color-text;
        }
      }

      button {
        display: flex;
        gap: 1em;
        background-color: transparent;
        box-shadow: none;
        border: 4px solid transparent;

        &:focus {
          border: 4px solid transparent;
          box-shadow: none;
        }

        &::after {
          display: none !important;
        }
      }
    }

    &.pro-acc {
      background-color: var(--carribean-blue-4);
    }

    &.elite-acc {
      background-color: var(--zimtra-purple-1);
    }

    &.intermediate-acc {
      background-color: #bf9b30;
    }

    &.simulator-acc, &.sim-acc {
      background-color: #E3E2E0;

      span {
        color: #626160;
      }
    }
  }

  .sb-avatar {
    color: c.$deepest-blue-2 !important;
    background: linear-gradient(
      to right,
      #5ecaf8 0%,
      #5fcbf3 13.75%,
      #60cfe3 31.13%,
      #63d6c9 50.37%,
      #67deaa 68.61%,
      #78f0c7 100%
    );

    img {
      object-fit: cover;
    }
  }

  .sb-avatar__text {
    color: c.$deepest-blue-2 !important;
  }

  .taskbar-page-title {
    color: var(--cool-gray-10);
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin: 0;

    @include devices.mobile {
      @include fs-16;
    }
  }
}

@use "../ui-kit/colors.scss" as c;
@use "../ui-kit/devices.scss";
@import "/src/ui/ui-kit/mixins.scss";

.account-setup-wrapper {
  width: fit-content;
}

.sim-upgrade-section {
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;

  i {
    font-size: 24px;
    color: #888a9b;
  }
}

.sim-upgrade-button {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.edit-profile-main-button {
  background-color: c.$new-leaf-green-5;
  border-color: c.$new-leaf-green-5;

  &:hover {
    background-color: c.$new-leaf-green-4;
    border-width: 1px;
    margin: 0;
  }
}

.sm-14 {
    @include fs-14;
}

.sim-upgrade-section-body {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 8px;

  img {
    cursor: pointer;
  }

  & > :nth-child(3),
  & > :nth-child(4) {
    display: flex;
    width: 260px;
    align-items: center;
    gap: 8px;
    text-align: left;

    & > :first-child {
      width: 100px;
    }
    & > :nth-child(2) {
      width: 120px;
    }
  }
}

.sim-body {
  display: flex;
  flex-direction: column;
  gap: 47px;
  @include fs-16;
}

.sm-grey {
  @include fs-14;
  color: var(--deepest-blue-8);
}

.sm-bolder {
  @include fs-14;
  font-weight: 500;
}

.flex-col {
  flex-direction: column;
}

.g-8 {
  gap: 8px;
}

@use "../colors.scss" as c;

html {
  .accordion-button::after {
    background-image: url("/images/checked.svg");
    background-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("/images/checked-second.svg");
    transition: initial;
    transform: initial;
  }
}
@use "../../ui-kit/colors.scss" as c;

.search-block {
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 350px;
  margin-bottom: 30px;
  margin-top: 8px;

  .search-upper {
    display: flex;
    gap: 10px;
    align-items: center;

    img {
      margin: 0 !important;
    }



    input {
      padding: 12px 16px;
      background-color: c.$deepest-blue-3;
      margin-left: 5px;
      border: 1px solid c.$deepest-blue-3;
      width: 140px;
      outline: none;
      color: c.$white;

      &:focus-visible {
        border: 1px solid c.$carribean-blue-5;
      }

      &.chosen-exact-match {
        border: 1px solid c.$new-leaf-green-5;
      }
    }
  }

  .search-lower {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 140px;
    margin-left: 5px;
    background-color: c.$deepest-blue-4;

    span {
      cursor: pointer;
      padding: 12px;

      &.exact-match {
        background-color: c.$vibrant-green-7;
        color: black;
      }

      &:hover:not(.exact-match) {
        background-color: rgba(c.$carribean-blue-5, 0.12);
        ;
      }
    }
  }
}
@use "ui/ui-kit/colors.scss" as c;
@use "/src/ui/ui-kit/devices.scss";
@import "ui/ui-kit/mixins.scss";

.chat-inner-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .chat-messages-list {
    flex: 1 75%;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    
    @include devices.mobile {
      flex: none;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    .empty-chat {
      color: c.$deepest-blue-7;
      @include fs-16;
      font-weight: 400;
      padding: 106px 0;
      text-align: center;
      border: 1px solid c.$deepest-blue-4;
      background: c.$deepest-blue-3;
      
      @include devices.mobile {
        padding: 80px 0;
      }
    }

    .not-empty-chat {
      border: 1px solid c.$deepest-blue-4;
      background: c.$deepest-blue-3;

      ul {
        margin: 0;
        padding: 12px 24px;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
      }

      li {
        width: 74%;
      }

      .message-text {
        @include fs-16;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .message-date {
        color: c.$deepest-blue-7;
        @include fs-14;
        font-weight: 400;
        margin-top: 10px;
      }
    }

    .avatar {
      &.picture {
        aspect-ratio: 1/1;
        max-height: 50px;
        clip-path: circle(50%);

        &:not(.support) {
          img {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }
      }

      &.support {
        background: c.$deepest-blue-2-primary;
        border-radius: 100px;
        display: flex;
        width: 50px;
        align-items: center;
        justify-content: center;

        img {
          margin-bottom: 4px;
        }
      }
    }

    .message-content {
      width: calc(100% - 50px);
    }

    .area {
      background: rgba(120, 240, 198, 0.15);
      padding: 12px 16px;

      pre {
        font-size: inherit;
        font-family: inherit;
        margin: 0;
      }
    }

    .customer-message {
      align-self: flex-end;

      .message-date {
        float: right;
      }
    }

    .support-message {
      .area {
        background: rgba(55, 186, 243, 0.05);
      }
    }

    .inner-container {
      display: flex;
      align-items: flex-start;
      gap: 9px;
    }
  }

  .chat-message-field-area {
    padding-top: 30px;
    width: 100%;
    flex: 1 25%;

    >div {
      padding: 9px 15px 20px;
      border: 1px solid c.$deepest-blue-4;
      margin: 0 auto;
      max-width: 90%;
    }

    textarea {
      width: 100%;
      height: 100%;
      background: transparent;
      border: 0;
      resize: none;
      outline: none;
      color: c.$white;
      max-height: 220px !important;

      &::placeholder {
        color: c.$deepest-blue-7;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      button {
        padding: 6px 27px;
      }
    }
  }

  .attachment {
    display: inline-block;
    margin: 0 10px 0 0;

    a {
      @include fs-14;
      color: c.$carribean-blue-5;
    }
  }
}
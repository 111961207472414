$accent-color: #67dfaa;
$accent-color-semi-trans: #90d0aa30;
$accent-grey-color: #8E857C;

$color-white : #fff;

$deepest-blue-1: #060922;
$deepest-blue-2: #111537;
$deepest-blue-3: #232854;
$deepest-blue-4: #383D6A;
$deepest-blue-5: #4c517b;
$deepest-blue-6: #6F7397;
$deepest-blue-7: #9699B2;
$deepest-blue-8: #C6C8D5;
$deepest-blue-9: #E7E7ED;

$carribean-blue-4: #00A7EF;
$carribean-blue-5: #37BAF3;
$carribean-blue-524: #37BAF33D;

$zimtra-carribean-blue-1: #3b99f9;
$zimtra-carribean-blue-2: #5ecaf8;
$zimtra-carribean-blue-3: #419bf6;
$zimtra-carribean-blue-4: #44a0f1;
$zimtra-carribean-blue-5: #4caee1;
$zimtra-carribean-blue-6: #58c4c7;
$zimtra-carribean-blue-7: #5fcbf3;
$zimtra-carribean-blue-8: #60cfe3;
$zimtra-carribean-blue-9: #63d6c9;

$blue-gray-5: #627d98;

$teal-1: #014d40;
$teal-2: #0c6b58;
$teal-3: #147d64;
$teal-4: #199473;
$teal-5: #27ab83;
$teal-6: #3ebd93;
$teal-7: #65d6ad;
$teal-8: #8eedc7;
$teal-9: #c6f7e2;
$teal-10: #effcf6;

$cool-gray-1: #1f2933;
$cool-gray-2: #323f4b;
$cool-gray-3: #3e4c59;
$cool-gray-4: #52606d;
$cool-gray-5: #616e7c;
$cool-gray-6: #7b8794;
$cool-gray-7: #9aa5b1;
$cool-gray-8: #cbd2d9;
$cool-gray-9: #e4e7eb;
$cool-gray-10: #f5f7fa;

$zimtra-purple-1: #6430FF;

$new-leaf-green-1: #007e48;
$new-leaf-green-2: #03a660;
$new-leaf-green-3: #25b376;
$new-leaf-green-4: #43cb91;
$new-leaf-green-5: #67DEAA;
$new-leaf-green-5-012: rgb(103, 222, 170, 0.12);
$new-leaf-green-5-02: rgb(103, 222, 170, 0.2);

$vibrant-green-7: #78F0C6;

$yellow-vivid-5: #F0B429;
$yellow-vivid-6: #F7C948;

$red-vivid-1: #610316;
$red-vivid-2: #8a041a;
$red-vivid-3: #ab091e;
$red-vivid-4: #cf1124;
$red-vivid-5: #e12d39;
$red-vivid-6: #ef4e4e;
$red-vivid-7: #f86a6a;
$red-vivid-8: #ff9b9b;
$red-vivid-9: #ffbdbd;
$red-vivid-10: #ffe3e3;

$animation-cool-gray-1: #fff3;
$animation-cool-gray-2: #ffffff80;
$animation-cool-gray-3: #ffffffb3;

$shadow-gray-color-1: #1f29330f;
$shadow-gray-color-2: #1f29331a;
$shadow-gray-color-3: #1f293314;

$accent-color-semi-transparent: #90d0aa7a;
$accent-color-very-transparent: #00800021;

$accent-color-dark: #370c0c;

$accent-color-text: white;
$accent-yellow-color-text: #f0f01e;

$hover-background-color: #555555;
$hover-color: white;

$main-text-color: #797979;
$semi-grey-text-color: #8c8d85;

$error-light-red-color: #e1786ed6;

$black: #000;
$white: #fff;
$deepest-blue-2-primary: #111537;
$new-leaf-green-5-primary: #67DEAA;
$cool-gray-10: #F5F7FA;
$vibrant-green-7-primary: #78F0C6;
$screen-phone: 639px;
$screen-tablet: 979px;
$screen-desktop: 1279px;
$screen-desktop-x-large: 1400px;

@mixin mobile {
  @media (max-width: ($screen-phone)) {
    @content;
  }
}

@mixin not-mobile {
  @media (min-width: ($screen-phone + 1)) {
    @content;
  }
}

@mixin tablet {
  @media (min-width:($screen-phone + 1)) and (max-width: ($screen-tablet - 1)) {
    @content;
  }
}

@mixin mobile-resolution {
  @media (max-width: ($screen-tablet - 1)) {
    @content;
  }
}


@mixin desktop {
  @media (min-width: $screen-tablet) {
    @content;
  }
}

@mixin desktop-large {
  @media (min-width: ($screen-desktop + 1)) {
    @content;
  }
}

@mixin desktop-x-large {
  @media (min-width: ($screen-desktop-x-large + 1)) {
    @content;
  }
}
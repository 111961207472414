@use "../ui-kit/colors.scss" as c;
@import "../ui-kit/mixins.scss";

.faq {
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  width: 100%;
}

.title {
  color: #f5f7fa;
  font-family: "Syne";
  @include fs-20;
  font-weight: 700;
  margin: 0;
  text-align: start;
  width: 100%;
}

.inThisSection {
  border-bottom: 1px solid #383d6a;
  background: c.$deepest-blue-3;
  border-top: 8px solid #78f0c6;
  text-align: left;
  color: c.$cool-gray-10;
  font-family: "Syne";
  @include fs-20;
  font-weight: 700;
  padding: 9px 0;
  width: 100%;
  padding-left: 20px;
}

.breadcrumb {
  display: flex;
  @include fs-12;

  ol {
    display: flex;
    gap: 10px;
    list-style-type: none;

    li {
      padding-left: 0 !important;

      a {
        text-decoration: none;
      }
      &:before {
        display: none;
      }
    }
  }
}

.slash {
  margin-left: 5px;
  color: white;
}

.crumbTitle {
  color: white;

  &.activeBreadcrumb {
    color: c.$new-leaf-green-5;
    border-bottom: 1px solid c.$new-leaf-green-5;
  }
}

.docListBlock, .faqListBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: transparent;
}

.faqListBlock {
  background: c.$deepest-blue-3;
  width: 100%;
}

.docList {
  width: -webkit-fill-available;

  li {
    cursor: pointer;
    @include fs-16;
    padding: 10px 19px;
    margin: 6px 0 4px 8px;
    text-align: left;

    &:hover {
      background: #383d6a;
      border-radius: 0;
      color: #67dfaa;
    }
  }
}

@use "./ui/ui-kit/colors.scss" as c;
@use "/src/ui/ui-kit/devices.scss";
@use "./ui/ui-kit/overrides.scss";
@import "/src/ui/ui-kit/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Syne:wght@400;500;600&display=swap");

.App {
  text-align: center;
  color: var(--color-white);
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

a {
  color: var(--new-leaf-green-5);

  &:hover,
  &:focus {
    color: var(--vibrant-green-7);
  }
}

.form-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .form-group-block {
    text-align: left;
    position: relative;
    max-width: 400px;
    width: 100%;

    .form-label {
      font-size: 18px;
      color: rgba(10, 10, 10, 0.65);
    }
  }
}

.link {
  color: var(--carribean-blue-5);
  @include fs-14;
  text-decoration-line: underline;
  cursor: pointer;
}

.see-password {
  color: var(--cool-gray-6);
  position: absolute;
  bottom: 5px;
  right: 15px;
  cursor: pointer;
}

.form-wrapper button,
.default-button {
  margin: 0 10px;
  padding: 0 28px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: var(--new-leaf-green-5);
  color: var(--deepest-blue-2);
  line-height: 50px;
  border-radius: 0;
  border: 1px solid var(--new-leaf-green-5);
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.96px;

  &.sm-button {
    font-size: 0.875rem;
    line-height: 40px;
    padding: 0 15px;
  }

  &.button-without-bg {
    background: transparent;
    color: var(--color-white);
  }

  &:hover,
  &:focus {
    background: var(--vibrant-green-7);
    border: 1px solid var(--vibrant-green-7);
    color: var(--deepest-blue-2);
  }

  &.btn.disabled,
  &.btn:disabled {
    background: var(--new-leaf-green-5);
    border: 1px solid var(--new-leaf-green-5);
    color: var(--deepest-blue-2);
  }
}

.error-message {
  padding: 5px;
  color: var(--color-white);
  border-radius: 5px;
  background-color: c.$error-light-red-color;
}

.main-button {
  cursor: pointer;
  margin: 0 auto;
  width: fit-content;
  padding: 0.5em;
  border: none;
  font-weight: 500;
  font-size: 1rem;
  color: var(--deepest-blue-2);
  opacity: 0.8;
  transition: 0.3s ease-in-out;
  background-color: var(--new-leaf-green-5);
  min-width: 100px;

  &:disabled {
    background-color: lightgrey;
  }

  &:hover {
    opacity: 1;
  }
}

.semi-bold {
  font-weight: 500;
}

.react-tooltip {
  // we use important because this is 3rd party library and it uses its own styles
  background-color: var(--deepest-blue-1) !important;
  border: 1px solid var(--deepest-blue-5) !important;
  border-radius: 0 !important;
  color: var(--color-white) !important;
  opacity: 1 !important;
  z-index: 2;

  @include devices.mobile {
    width: min-content !important;
  }
}

.fs-italic {
  font-style: italic;
}

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: c.$deepest-blue-2; /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: c.$deepest-blue-4; /* Color of the thumb */
  border-radius: 6px; /* Roundness of the thumb */
  border: 3px solid c.$deepest-blue-2; /* Padding around thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Width of the scrollbar */
  scrollbar-color: c.$deepest-blue-4 c.$deepest-blue-2; /* Thumb and track color */
}

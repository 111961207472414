@use "../../ui-kit/colors.scss" as c;

.attachment-table-page-wrapper {
  text-align: initial;
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 100px);
  display: flex;
}

.before {
  flex-direction: column-reverse;
}

.after {
  flex-direction: column;
}
@use "ui/ui-kit/colors.scss" as c;
@use "/src/ui/ui-kit/devices.scss";
@import "ui/ui-kit/mixins.scss";

.cabinet-modals {
  padding: 0;
  border: 1px solid c.$deepest-blue-3;
  border-top: 0 !important;

  >div {
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal-inner-header {
    background: c.$vibrant-green-7;
    color: c.$deepest-blue-2-primary;
    padding: 15px 40px 15px 10px;
    margin: 0;

    h2 {
      @include fs-16;
      font-weight: 600;
      margin: 0;
    }
  }

  .modal-inner-body {
    padding: 20px 63px;
    
    @include devices.mobile {
      padding: 20px 10px;
    }
  }

  .modal-inner-footer {
    padding: 20px 0 40px;
    margin: 0 63px;
    gap: 40px;
    border-top-color: c.$deepest-blue-4;
    
    @include devices.mobile {
      gap: 10px;
    }

    button {
      border-radius: 0;
      color: transparent;
      padding: 5px 62px;
      
      @include devices.mobile {
        padding: 5px 22px;
      }

      &.cabinet-secondary-btn {
        border-color: c.$deepest-blue-8;
        padding: 6px 62px;

        @include devices.mobile {
          padding: 5px 22px;
        }

        span {
          color: c.$white;
        }

        &:hover {
          background-color: c.$white;
          border-color: c.$white;

          span,
          i {
            color: c.$deepest-blue-2-primary;
          }
        }
      }

      &.cabinet-primary-btn {
        span {
          color: c.$deepest-blue-2-primary;
        }
      }
    }
  }
}

.cabinet-input {
  input {
    transition: 0.3s linear;

    &+span {
      display: none;
    }

    &::placeholder {
      color: c.$deepest-blue-7;
    }
  }

  &:focus,
  &:hover {
    input {
      border: 1px solid var(--cool-gray-7);
    }
  }
}

.radio-group-container {
  input+span {
    color: c.$white;
    @include fs-14;
  }
}

.error-text {
  color: c.$red-vivid-8;
  @include fs-12;
  margin-top: 3px;
}

.error-field {
  line-height: 0;

  .cabinet-input {
    input {
      border-color: c.$red-vivid-5 !important;
    }
  }

  .cabinet-textarea {
    border-color: c.$red-vivid-5 !important;
  }

  >* {
    line-height: initial;
  }
}

.cabinet-textarea {
  transition: 0.3s linear;
  background: transparent;
  border: 1px solid c.$deepest-blue-4;
  padding: 12px 13px;
  resize: none;
  color: c.$white;
  outline: none;

  &::placeholder {
    color: c.$deepest-blue-7;
  }

  &:focus,
  &:hover {
    border: 1px solid var(--cool-gray-7);
  }

}
@use "../ui-kit/colors.scss" as c;
@use "../ui-kit/devices.scss";
@import "/src/ui/ui-kit/mixins.scss";

.account-setup-wrapper {
  padding: 0 40px 17px 10px;
  font-size: 1rem;
  height: 100%;
  color: c.$accent-color-text;
  overflow: auto;
  width: 100%;

  @include devices.desktop {
    padding: 40px;
  }

  @include devices.tablet {
    padding: 0 40px 17px 40px;
  }

  header {
    padding: 10px 12px;
    background-color: c.$accent-color;
    text-align: start;
    font-weight: bold;
    color: c.$deepest-blue-2;
    @include fs-16;
  }

  .account-setup-header {
    font-weight: bold;
    text-align: initial;
  }

  .status-yellow-bg {
    background-color: c.$yellow-vivid-6;
  }

  .status-yellow-font {
    color: c.$accent-yellow-color-text;
  }

  .spinner {
    align-self: center;
    // margin-top: 50%;
  }

  .not-valid-form {
    color: c.$error-light-red-color;
  }
}

.faq-opened-dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;

  .black-block {
    background-color: rgba(0, 0, 0, 0.21);
    width: 100%;
    height: 100%;
    position: fixed;
  }

  .faq-dialog-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80vh;
    overflow: auto;
    background-color: c.$deepest-blue-2;
    width: 100%;
    max-width: 1028px;
    padding: 10px;
    z-index: 11;

    .faq-dialog {
      text-align: left;
      padding: 40px 16px;

      @include devices.desktop {
        padding: 40px 60px;
      }

      h2 {
        margin: 1.4rem 0;
      }

      img {
        max-width: 100%;
      }

      p {
        line-height: 25px;
      }

      td {
        padding-left: 15px;
        padding-bottom: 20px;
      }
    }

    .faq-button {
      margin-bottom: 10px;
      margin-top: 0.5em;
      width: fit-content;
      color: c.$deepest-blue-2;
      background-color: c.$accent-color;
      border: none;
      opacity: 0.8;
      transition: 0.3s ease-in-out;
      padding: 0.5em 1em;
      font-weight: bold;

      &:disabled {
        background-color: grey;
        color: darkgrey;
      }

      &:focus {
        box-shadow: none;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

.wire-opened-dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;

  .black-block {
    background-color: rgba(0, 0, 0, 0.21);
    width: 100%;
    height: 100%;
    position: fixed;
  }

  .wire-dialog-block {
    position: absolute;
    top: 50%;
    min-width: 300px;
    max-width: 620px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: c.$deepest-blue-2;
    z-index: 11;

    .wire-dialog-bottom-buttons {
      padding-bottom: 40px;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;

      @include devices.desktop {
        flex-direction: row;
      }
    }

    .fixed-button-size {
      button {
        width: 182px;
      }
    }



    .wire-dialog {
      padding: 40px 60px 0;
      position: relative;

      @include devices.mobile {
        padding: 40px 16px 0;
      }

      .wire-dialog-subheader {
        @include fs-16;
        font-weight: bold;
      }

      .wire-image-block {
        position: relative;
        background-color: c.$accent-color-semi-trans;
        background-image: linear-gradient(to right,
            c.$accent-color 50%,
            transparent 50%),
          linear-gradient(to right, c.$accent-color 50%, transparent 50%),
          linear-gradient(to bottom, c.$accent-color 50%, transparent 50%),
          linear-gradient(to bottom, c.$accent-color 50%, transparent 50%);
        background-position: left top, left bottom, left top, right top;
        background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
        background-size: 17px 1.5px, 17px 1.5px, 1.5px 17px, 1.5px 17px;

        .wire-image {
          width: 50%;
          height: 100%;
          max-height: 60vh;
          margin: 1em;
        }

        .pad-btn {
          padding: 1em 0 4em;
        }

        .wire-image-buttons {
          position: absolute;
          display: flex;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }
      }

      .upload-button-block {
        position: relative;

        button {
          margin: 0;
          margin-bottom: 20px;
          margin-top: 20px;
          width: fit-content;
          color: white;
          transition: 0.3s ease-in-out;
          border: none;
          padding: 40px 20px;
          color: c.$accent-color;
          padding-left: 60px;
          text-align: initial;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 20px;
          background-image: linear-gradient(to right,
              c.$accent-color 50%,
              transparent 50%),
            linear-gradient(to right, c.$accent-color 50%, transparent 50%),
            linear-gradient(to bottom, c.$accent-color 50%, transparent 50%),
            linear-gradient(to bottom, c.$accent-color 50%, transparent 50%);
          background-position: left top, left bottom, left top, right top;
          background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
          background-size: 17px 1.5px, 17px 1.5px, 1.5px 17px, 1.5px 17px;

          &:disabled {
            background-color: grey;
            color: darkgrey;
          }

          &:focus {
            box-shadow: none;
          }

          &:hover {
            background-color: c.$accent-color;
            color: white;
          }
        }

        .upload-plus-icon-block {
          position: absolute;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 50%;
          padding: 1px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: c.$accent-color;
          left: 21px;
          top: 50%;
          width: 20px;
          height: 20px;
        }
      }
    }

    .wire-button,
    .wire-button-transparent {
      margin: 0.5em;
      width: fit-content;
      color: c.$deepest-blue-2;
      background-color: c.$accent-color;
      border: 2px solid transparent;
      font-weight: bold;
      opacity: 0.8;
      transition: 0.3s ease-in-out;
      padding: 0.5em 1em;

      &:disabled {
        background-color: grey;
        color: darkgrey;
      }

      &:focus {
        box-shadow: none;
      }

      &:hover {
        opacity: 1;
      }
    }

    .wire-button-transparent {
      background-color: transparent;
      border: 1.5px solid c.$deepest-blue-8;
      opacity: 1;
      color: c.$deepest-blue-8;
    }

    .upload-wire-button {
      color: c.$accent-color;
      background-color: transparent;
      display: flex;
      align-items: center;
      gap: 0.5em;
      transition: 0.3s ease-in-out;
      font-weight: 500;

      &:hover {
        border: 2px solid c.$accent-color;
      }
    }

    .wire-button-red {
      background-color: c.$red-vivid-8;
    }
  }
}
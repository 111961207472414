@use "../ui-kit/colors.scss" as c;
@use "../ui-kit/devices.scss";
@import "/src/ui/ui-kit/mixins.scss";

.support-wrapper {
  position: relative;
  padding: 18px 1.5rem 1.5rem 1.5rem;
  font-size: 1rem;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: auto;

  .spinner {
    margin-top: 35px;
  }

  h2 {
    color: var(--cool-gray-10);
    font-weight: bold;
    text-align: initial;
    font-family: "Syne";
    @include fs-20;
    margin-bottom: -35px;
    letter-spacing: 0.4px;

    @include devices.mobile {
      @include fs-16;
      line-height: 16px;
    }
  }

  .support-table-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 13px;

    button {
      background: c.$accent-color;
      color: c.$deepest-blue-2;
      border-radius: 0;
      padding: 8px 12px;

      span {
        display: flex;
        align-items: center;
      }

      i {
        font-size: 24px;
        margin-right: 10px;
      }
    }
  }

  .support-table {
    position: relative;
    max-width: 1280px;

    @include devices.mobile {
      overflow: auto;
    }

    .support-table-body {
      overflow: auto;

      .no-wrap-cell {
        white-space: nowrap;
      }
    }

    .row-overlay-menu {
      div {
        &:last-child {
          border-radius: 0;
          background-color: c.$deepest-blue-2;

          @include devices.desktop {
            min-width: 250px;
          }

          @include devices.tablet {
            min-width: 250px;
          }
        }
      }

      i {
        color: c.$deepest-blue-7;
        transform: rotate(90deg);
      }
    }

    .table-wrapper {
      width: 100%;
      @include fs-12;
      text-align: left;

      .support-no-data-block {
        position: absolute;
        bottom: -90px;
        left: 0;
        width: 100%;
        height: 80px;
        background-color: c.$deepest-blue-3;
        border: 1px solid c.$deepest-blue-4;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: c.$deepest-blue-7;
          @include fs-16;
        }
      }

      thead {
        @include fs-12;

        tr {
          th {
            height: 48px;
            background-color: c.$deepest-blue-1;
            padding: 0 12px !important;

            &:first-child {
              text-transform: uppercase;
              min-width: 70px;//is set for ID column to have constant width to prevent shaking during sorting
            }

            &:not(:first-child):not(:last-child) {
              border-left: 1px solid c.$deepest-blue-4;
              border-right: 1px solid c.$deepest-blue-4;
            }
          }
        }
      }

      tbody {
        tr {
          background-color: c.$deepest-blue-3;

          &:hover {
            background-color: c.$deepest-blue-4;

            td {
              border-color: c.$deepest-blue-3;
            }
          }
        }

        td {
          border: 1px solid c.$deepest-blue-4;
          padding: 12px !important;
        }
      }
    }
  }
}

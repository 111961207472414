@use "../ui-kit/colors.scss" as c;

.section-not-found {
  background: c.$deepest-blue-2;
  min-height: 100vh;

  .wrapper {
    text-align: center;
    padding: 390px 0 160px;

    h1 {
      font-size: 60px;
      color: var(--color-white);
      margin-bottom: 60px;

      span {
        font-size: 74px;
        line-height: 67px;
        display: block;
        margin-bottom: 30px;
      }
    }

    .error {
      color: var(--red-vivid-4);
      margin-bottom: 30px;
    }

    .back-to-main {
      cursor: pointer;
    }
  }
}

@use "../../ui-kit/colors.scss" as c;

.table-wrapper {
  thead {
    font-size: 1.2em;
    text-transform: capitalize;

    tr {
      th {
        padding-bottom: 10px !important;

        .column-title {
          display: flex;

          span {
            &:first-child {
              margin-right: 10px;

              &.ascending {
                transform: rotate(90deg);
              }
              &.descending {
                transform: rotate(270deg);
              }

              i {
                font-weight: bold;
              }
            }
          }
        }

        .select-filter-block {
          display: flex;
          padding: 5px;
          flex-direction: row;
          align-items: center;
          border: 1px solid c.$accent-color;
          border-radius: 5px;
        }
      }

      &:first-child {
        display: none;
      }
    }
  }

  tbody {
    tr {
      position: relative;

      .relative-wrapper {
        position: relative;
        width: 100%;
        height: auto;
      }

      .hover-content {
        position: absolute;
        background: linear-gradient(
          to right,
          transparent 0%,
          c.$deepest-blue-4 60%,
          c.$deepest-blue-4 100%
        );
        color: white;
        opacity: 0;
        border: none !important;
        justify-content: flex-end;

        width: 140px;
        height: 100%;
        top: 0;
        transform: none;
        right: 1px;
        display: none; /* Hide it by default */
        align-items: center;
        z-index: 1;
      }

      &:hover .hover-content {
        display: flex;
        opacity: 1;
      }
    }
  }

  .hide {
    display: none;
  }
}

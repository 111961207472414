@use "../ui-kit/colors.scss" as c;
@use "../ui-kit/devices.scss";
@import "/src/ui/ui-kit/mixins.scss";

.navlink-wrapper {
  position: relative;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  text-align: start;
  text-decoration: none;
  color: c.$color-white;
  padding: 0 20px 0 14px;
  @include fs-12;
  font-weight: 600;

  &.active-link {
    background-color: c.$deepest-blue-3;
    color: c.$new-leaf-green-5;
  }

  p {
    margin-bottom: 0;
  }

  img {
    width: 20px;
    margin-right: 5px;
  }

  .navlink-lower-blocker {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
  }

  &:hover:not(.active-link) {
    cursor: pointer;
    background-color: c.$deepest-blue-2;
  }
}

.blacked-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.243);
  z-index: 8;
}

.side-block {
  position: relative;
  display: block;
  flex-direction: column;
  overflow: auto;
  background-color: c.$deepest-blue-1;
  border-top: 8px solid #78f0c6;

  @include devices.mobile {
    position: absolute;
    z-index: 13;
    height: 100vh;
    width: 100%;
  }

  @include devices.tablet {
    position: absolute;
    z-index: 13;
    height: 100vh;
    width: 100%;
    max-width: 360px;
  }

  @include devices.desktop {
    display: flex;
  }

  .fixed-side-elements {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 25px;

    .block-content {
      pointer-events: none;
      opacity: 0.5;
    }

    .upper-side {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .divider {
      margin-left: 10px;
    }

    .lower-side {
      text-align: initial;
      overflow: auto;

      .child-block {
        background-color: #8080800f;
        width: 100%;

        .child-wrapper {
          overflow-wrap: break-word;
          text-decoration: none;
          color: c.$color-white;
          cursor: pointer;
          padding: 5px;
          padding-left: 10px;

          &:hover {
            background-color: #555555;
            color: white;
            cursor: pointer;
            border-radius: 10px;
            transition: 0.75s cubic-bezier(0.25, 1, 0.2, 1);
            padding-bottom: 7px;

            &::marker {
              color: c.$new-leaf-green-5;
            }
          }
        }
      }
    }
  }
}

.side-block-collapsed {
  overflow: hidden;

  @include devices.mobile {
    display: none;
  }

  @include devices.tablet {
    display: none;
  }
}

.taskbar-brand {
  height: 80px;
  margin: 0 auto;
  position: relative;

  .close-sidebar-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;

    @include devices.desktop {
      display: none;
    }
  }

  @include devices.mobile {
    margin-left: 20px;
  }

  @include devices.tablet {
    margin-left: 20px;
  }

  a {
    height: 100%;
    display: flex;
    align-items: flex-start;
    position: relative;

    img {
      margin-top: 16px;
      height: 32px;
    }
  }
}

.taskbar-brand-collapsed {
  height: 80px;
}

.collapsed-sidebar {
  padding: 9px 20px 9px 14px;
  height: 36px;
  gap: 30px;

  img {
    width: 20px;
  }
}

.expand-button {
  display: flex;
  align-items: center;
  gap: 17px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;

  p {
    @include fs-12;
    font-weight: 500;
    margin-bottom: 0;
  }

  img {
    transition: transform 0.3s ease;

    &:hover {
      animation: bounce 0.6s ease-in-out;
    }
  }
}

.collapse-button {
  left: 14px;
  transform: translateX(0);
  img {
    transform: rotate(180deg);

    &:hover {
      animation: none;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-1.5px);
  }
  70% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}

.sideblock-black-bg {
  display: none;

  @include devices.tablet {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.342);
    z-index: 10;
  }
}
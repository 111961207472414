.risk-settings-body .body-item {
  position: relative;

  .hv-triangle {
    display: none;
    position: absolute;
    left: -15px;
    top: -3px;
    color: #90d0aa;
  }

  .body-item-right {
    padding: 10px;
    display: flex;
    gap: 10px;
    color: var(--deepest-blue-9);

  }
}

.tooltip-inner {
  max-width: 250px !important;

  .rs-overlay-status {
    text-align: left;
  }
  
  // .overlay-nowrap {
  //   white-space: nowrap;
  // }
}

.risk-settings-body .body-item:hover {

  .body-item-right.editable {
    cursor: pointer;
  }

  .hv-triangle {
    display: block;
    transform: translate(-5px, 0) scale(1.2);
  }
}

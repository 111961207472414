.form-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20vh;

    .spaced-between {
        display: flex;
        justify-content: space-between;
    }

    .form-checkbox {
        a {
            margin-left: 10px;
        }
    }
}



@use "ui/ui-kit/colors.scss" as c;
@use "/src/ui/ui-kit/devices.scss";
@import "ui/ui-kit/mixins.scss";

.cabinet-modals {
  &.new-request-modal,
  &.risk-settings-modal,
  &.toggle-account-modal,
  &.custom-request-modal,
  &.edit-risk-setting-modal {
    width: 540px;

    .left-side {
      width: 30%;
      margin-top: 12px;
    }

    .modal-row {
      margin: 21px 0;

      &.first {
        margin-top: 0;
      }
    }

    hr {
      border-color: c.$deepest-blue-4;
      opacity: 1;
      margin: 0;
    }
  }

  .request-dropdown {
    > div {
      outline: none;

      > span {
        background: transparent;
        border-radius: 0;
        border-color: c.$deepest-blue-4;
        padding: 11px 52px 11px 16px;
        width: 100%;
        color: c.$white;
        transition: 0.3s linear;

        &.request-dropdown__placeholder {
          color: c.$deepest-blue-7;
        }
      }

      > div:nth-child(3) {
        border-radius: 0;
        background: c.$deepest-blue-2;
        color: c.$white;

        > div div {
          &:hover,
          &:focus {
            background-color: c.$deepest-blue-3;
          }
        }
      }
    }
  }

  .disable-row {
    opacity: 0.5;
    pointer-events: none;
  }

  &.toggle-account-modal {
    .modal-inner-body {
      @include devices.mobile {
        padding: 20px 10px 0;
      }

      & > div {
        @include devices.mobile {
          margin: 10px 0 10px;
          flex-direction: column;
          gap: 10px;
        }
      }
    }

    .react-calendar__month-view__days {
      .react-calendar__tile {
        &:disabled,
        &[disabled] {
          opacity: 0.1;
        }
      }
    }

    .calendar-container {
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      .calendar-label {
        > div {
          > input {
            transition: 0.3s linear;

            &:focus,
            &:hover {
              border: 1px solid var(--cool-gray-7);
            }
          }

          > span {
            display: none;
          }
        }
      }
    }
  }

  &.risk-settings-modal {
    .modal-inner-body {
      padding: 20px 63px 0;

      @include devices.mobile {
        padding: 20px 10px 0;
      }

      & > div {
        @include devices.mobile {
          margin: 0 0 10px;
          flex-direction: column;
          gap: 10px;
        }
      }
    }

    .left-side {
      @include devices.mobile {
        width: 70%;
      }
      & + div {
        width: 70%;

        @include devices.mobile {
          width: 100%;
        }

        &.change-value {
          width: 34%;
          position: relative;
          
          @include devices.mobile {
            width: 50%;
          }
        }
      }
    }

    .select-category-dropdown {
      width: 82%;

      @include devices.mobile {
        width: 100%;
      }
    }

    .select-risk-settings-dropdown {
      width: 100%;
    }

    .current-price-row {
      .left-side {
        margin-top: 0;
      }
    }

    .category-value-tooltip {
      position: absolute;
      top: 12px;
      right: -39px;
    }
  }

  &.custom-request-modal {
    width: 680px;

    .modal-inner-body {
      padding: 39px 63px 20px;

      @include devices.mobile {
        padding: 20px 10px 0;
      }

      & > div {
        @include devices.mobile {
          margin: 10px 0 10px;
          flex-direction: column;
          gap: 10px;
        }
      }

      span {
        overflow: hidden;
      }

      .select-request-category-dropdown {
        width: 100%;
      }

      form > div {
        flex-direction: column;
      }
    }

    .upload-row {
      margin: 0 0 0 21%;
    }

    .left-side {
      width: 21%;

      @include devices.mobile {
        width: 70%;
      }

      & + div {
        width: 79%;

        @include devices.mobile {
          width: 100%;
        }
      }
    }

    .request-row {
      margin-bottom: 10px;
    }

    .request-dropdown {
      width: 55%;

      @include devices.mobile {
        width: 100%;
      }
    }

    .cabinet-input {
      width: 100%;
    }

    textarea {
      width: 100%;
      height: 180px;
    }
  }

  &.new-request-modal {
    .modal-inner-footer {
      button {
        width: 44%;

        @include devices.mobile {
          width: 100%;
        }
      }
    }

    .modal-inner-body {
      padding: 40px 63px;

      & > div {
        @include devices.mobile {
          flex-direction: column;
          gap: 10px;
        }
      }
    }

    .modal-inner-footer {
      padding: 40px 0;
      @include devices.mobile {
        flex-direction: column;
      }
    }

    .left-side {
      width: 28%;

      @include devices.mobile {
        width: 100%;
      }
    }

    .left-side + div {
      width: 58%;

      @include devices.mobile {
        width: 100%;
      }
    }
  }

  &.edit-risk-setting-modal {
    .left-side {
      & + div {
        width: 70%;

        &.change-value {
          width: 34%;
          position: relative;

          @include devices.mobile {
            width: 50%;
          }
        }
      }
    }

    .current-price-row {
      .left-side {
        margin-top: 0;
      }
    }

    .category-value-tooltip {
      position: absolute;
      top: 12px;
      right: -39px;
    }
  }
}

.upload-tooltip-content,
.category-value-tooltip-content {
  z-index: 100;
  padding: 8px 30px 8px 12px;
  border: 1px solid c.$deepest-blue-5;
  background: c.$deepest-blue-2-primary;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06),
    0px 10px 20px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0;
  max-width: 275px;

  span {
    color: c.$white;
    @include fs-12;
  }

  ul {
    padding-left: 18px;
    margin: 0;
  }
}

.upload-tooltip,
.category-value-tooltip {
  display: flex;

  i {
    color: c.$deepest-blue-7;
    font-size: 22px;
  }
}

.modal-window {
  .request-overlay {
    background-color: c.$deepest-blue-2;
    opacity: 50%;
  }
}

.modal-window {
  .modal-inner-footer {
    button {
      span {
        color: c.$deepest-blue-2;
      }

      &:first-child {
        background-color: c.$new-leaf-green-5;

        &:hover {
          background-color: c.$new-leaf-green-4;
        }
      }

      &.cancel-button {
        background-color: c.$red-vivid-6;

        &:hover {
          background-color: c.$red-vivid-5;
        }
      }
    }
  }
}

@use "../ui-kit/colors.scss" as c;
@use "../ui-kit/devices.scss";
@import "/src/ui/ui-kit/mixins.scss";

.marketdata-wrapper {
  padding: 0 1.5rem;
  font-size: 1rem;
  height: 100%;
  width: 100%;
  overflow: auto;

  button {
    @include fs-16;
    text-align: left;
    padding-left: 16px;
    width: 100%;
    font-weight: 400;
    border: 1px solid c.$deepest-blue-4;
    background-color: c.$deepest-blue-3;
    color: c.$accent-color-text;
    box-sizing: border-box;

    &:hover {
      margin: initial;
      border-width: 1px;
    }
  }

  .marketdata-header-wrapper {
    position: sticky;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 8;
    top: 0;
    background-color: c.$deepest-blue-2;
    padding-bottom: 10px;
    padding-top: 18px;

    @include devices.mobile {
      position: relative;
    }

    .marketdata-header {
      font-weight: bold;
      text-align: initial;

      @include fs-20;
      font-family: "Syne";

      @include devices.mobile {
        @include fs-16;
      }
    }

    .marketdata-header-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 15px;

      span {
        color: c.$deepest-blue-8;
        font-size: 14px;
        line-height: 20px;
      }

      .semi-bold {
        color: c.$white;
      }
    }
  }

  .marketdata-bundle {
    display: flex;
    align-items: center;
    gap: 1em;

    @include devices.mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    .bundle-text {
      font-weight: bold;
      font-size: 16px;
    }

    .marketdata-button-group {
      display: flex;
      flex-wrap: nowrap;
      width: 569px;
      gap: 8px;

      @include devices.mobile {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      button {
        padding: 16px;

        @include devices.mobile {
          width: 100%;
          padding: 8px;
        }

        &:disabled {
          opacity: 0.5;
        }
      }

      .primary-bundle {
        z-index: 3;
        background-color: var(--new-leaf-green-5);
        font-weight: 500;
        border: 1px solid var(--new-leaf-green-5);
        color: var(--deepest-blue-2);

        hr {
          display: none;
        }
      }

      .waiting-bundle {
        z-index: 3;
        box-shadow: inset 0 0 0 3px var(--new-leaf-green-5);
        border-color: var(--new-leaf-green-5);

        hr {
          display: none;
        }
      }
    }
  }

  .marketdata-main-block {
    text-align: left;
    display: grid;
    margin-top: 40px;
    grid-template-columns: repeat(auto-fill, minmax(270px, 360px));
    grid-auto-rows: min-content;
    justify-items: start;
    gap: 12px 20px;
    max-width: 1050px;

    .marketdata-item-block {
      border: 1px solid c.$deepest-blue-3;
      max-width: 360px;
      width: 100%;

      .market-title {
        background-color: c.$deepest-blue-3;
        @include fs-14;
        padding: 10px 16px;
      }

      .market-body {
        font-size: 12px;
        padding: 12px 0;
        display: flex;
        flex-direction: column;
        gap: 11px;

        .market-item {
          display: flex;
          position: relative;
          align-items: center;
          white-space: nowrap;
          line-height: 22px;
          padding: 0 16px;

          @include devices.mobile {
            white-space: wrap;
          }

          .market-item-dep-ref-info {
            font-size: 16px;
            margin-left: 5px;
            margin-top: 1px;
            color: var(--deepest-blue-7);
            cursor: help;
          }

          input {
            display: none;
            margin-right: 0.7em;
            opacity: 0;
            cursor: pointer;
            z-index: 2;

            &:checked ~ .market-checkmark {
              background-color: transparent;

              &::after,
              &:before {
                display: block;
              }
            }

            &:hover ~ .market-checkmark {
              background-color: blue;
            }

            &:disabled ~ .market-checkmark {
              background-color: lightblue;
            }
          }

          .market-checkmark {
            display: block;
            position: relative;
            border: 1px solid c.$new-leaf-green-5;
            cursor: pointer;
            height: 18px;
            width: 18px;
            margin-right: 8px;
            z-index: 1;

            &:after {
              content: "";
              position: absolute;
              transition: all 0.2s ease-in-out;
              display: none;
              left: 5.4px;
              top: 1.5px;
              width: 5px;
              height: 11px;
              border: 1px solid c.$deepest-blue-2;
              border-width: 0 1.5px 1.5px 0;
              border-radius: 1px;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }

            &:before {
              content: "";
              background-color: c.$new-leaf-green-5;
              width: 100%;
              height: 100%;
            }
          }

          span {
            &:nth-child(3) {
              @include fs-14;
              color: var(--cool-gray-10);
            }

            &:last-child {
              margin-left: auto;
              @include fs-16;
            }
          }

          &.added-item,
          &.removed-item {
            background-color: c.$carribean-blue-524;
          }

          &.removed-item {
            text-decoration: line-through;

            label > * span {
              text-decoration: line-through;
            }
          }

          &.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }
    }

    hr {
      margin: 0;
      margin-bottom: 1em;
    }
  }

  .confirmation-block {
    display: flex;
    justify-content: space-between;
    position: fixed;
    @include fs-14;
    bottom: 0;
    left: 0;
    text-align: left;
    width: 100%;
    border-top: 1px solid c.$deepest-blue-6;
    background-color: c.$deepest-blue-3;
    padding: 1em;
    z-index: 12;

    @include devices.mobile {
      flex-direction: column;
      @include fs-10;
    }

    .left-block {
      display: flex;
      flex-direction: column;
      gap: 20px;
      color: c.$deepest-blue-8;
      max-width: 755px;

      .first-block {
        display: flex;
        gap: 20px;

        @include devices.mobile {
          flex-direction: column;
          gap: 0;
        }
      }
    }

    .effective-date-block {
      display: flex;
      flex-direction: column;

      .effective-date-calendar {
        display: flex;
        gap: 20px;
        align-items: center;
      }

      .react-calendar {
        position: absolute;
        top: -285px;

        & > * button {
          text-align: center;

          &:disabled {
            opacity: 0.5;
          }
        }
      }

      .monthly-fee-text {
        margin-top: 0.25em;
        text-decoration: underline;
      }

      .previously-text {
        margin-left: 1em;
        color: red;
      }

      .calendar-container {
        .calendar-label {
          > div {
            > input {
              transition: 0.3s linear;
              padding: 12px 16px;

              &:focus,
              &:hover {
                border: 1px solid var(--cool-gray-7);
              }
            }

            > span {
              display: none;
            }
          }
        }
      }
    }

    ul {
      width: fit-content;

      li {
        .monthly-cost-item {
          display: flex;
          justify-content: space-between;
          gap: 1em;
          width: 297px;
        }
      }
    }

    input[type="date" i] {
      border: 1px solid black;
      border-radius: 5px;
      padding: 3px 5px;
    }

    .confirmation-buttons {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 20px;
      width: 211px;

      @include devices.mobile {
        width: 100%;
        gap: 14px;
      }

      &.spaced {
        justify-content: space-between;
      }

      &.modify {
        width: 258px;
      }

      button {
        height: 40px;
        font-weight: 600;
        letter-spacing: 1px;
        padding: 0 28px;
        text-align: center;

        @include devices.mobile {
          height: 35px;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .apply-changes-button {
        color: c.$deepest-blue-2;
        background-color: c.$accent-color;
        border: none;

        &:hover {
          border: 1px solid black;
        }

        &:disabled {
          background-color: c.$accent-color-semi-transparent;
        }
      }

      .discard-changes-button {
        border: 1px solid c.$accent-color-text;
      }

      .cancel-requet-button {
        color: c.$deepest-blue-2;
        font-weight: 600;
        background-color: c.$red-vivid-6;
        border: 1px solid transparent;

        i {
          margin-right: 5px;
          font-size: 24px;
        }
      }
    }

    p {
      margin-bottom: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .market-total {
    text-align: left;
    font-weight: bold;
    margin-top: 1em;
  }

  hr {
    opacity: 1;
    width: 500px;
    margin-bottom: 0;

    @include devices.mobile {
      width: 90%;
    }
  }

  .no-opacity {
    opacity: 0;
  }

  .bold-titles {
    font-weight: 500;
    @include fs-16;
    white-space: nowrap;
    color: c.$accent-color-text;

    @include devices.mobile {
      white-space: wrap;
      @include fs-12;
    }
  }

  .ml-4 {
    margin-left: 1em;
  }
}

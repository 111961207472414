@use "../colors.scss" as c;
@import "/src/ui/ui-kit/mixins.scss";

.stepper-block {
  color: c.$accent-color-text;
}

.stepper-wrapper {
  width: 100%;
  max-width: 1500px;
  display: flex;
  justify-content: space-around;
  padding: 1em 8vw 3em 8vw;
  overflow: hidden;

  .step-wrapper {
    position: relative;
    width: 100%;

    .step-name {
      @include fs-16;
      margin-bottom: 5px;
    }

    .step-line-wrapper {
      width: 100%;
      position: absolute;
      left: 50%;
    }
  }
}

@media all and (max-width: 955px) {
  .stepper-wrapper {
    .step-wrapper {
      .step-name {
        font-size: 0.6rem;
      }

      .step-line-wrapper {
        transform: scale(0.8);
        left: 35%;

        .left-short-line {
          width: 57%;
          left: 23px;
        }
      }
    }
  }
}

@media all and (max-width: 600px) {
  .stepper-wrapper {
    .step-wrapper {
      .step-name {
        font-size: 0.5rem;
      }

      .step-line-wrapper {
        transform: scale(0.7);
        left: 35%;

        .left-short-line {
          width: 65%;
        }
      }
    }
  }
}

@media all and (max-width: 470px) {
  .stepper-wrapper {
    .step-wrapper {
      .step-name {
        font-size: 0.4rem;
      }

      .step-line-wrapper {
        transform: scale(0.6);
        left: 30%;

        .left-short-line {
          width: 90%;
          left: 22px;
        }

        .right-short-line {
          left: -59%;
        }
      }
    }
  }
}

@media all and (max-width: 370px) {
  .stepper-wrapper {
    .step-wrapper {
      .step-name {
        font-size: 0.35rem;
      }

      .step-line-wrapper {
        transform: scale(0.5);
        left: 15%;

        .left-short-line {
          width: 105%;
        }
      }
    }
  }
}

.v-stepper-wrapper {
  display: flex;
  flex-direction: column;

  .step-wrapper,
  .first-step-wrapper,
  .last-step-wrapper {
    position: relative;
    text-align: initial;
    min-height: 70px;
    padding-left: 40px;
    padding-top: 0.8em;

    .refundable-fee-upload {
      display: flex;
      font-size: 0.8em;
      gap: 3em;
      text-align: initial;
      color: #797979;

      span {
        margin-bottom: 0.5em;
      }

      button {
        margin-top: .5em;
        width: fit-content;
        color: c.$deepest-blue-2;
        background-color: c.$new-leaf-green-5;
        border: none;
        border-radius: 0;
        opacity: 0.8;
        font-weight: 600;
        transition: .3s ease-in-out;

        &:disabled {
          background-color: grey;
          color: darkgrey;
        }

        &:focus {
          box-shadow: none;
        }

        &:hover {
          opacity: 1;
        }
      }

      .upload-button-block {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        justify-content: flex-start;
        width: fit-content;
      }
    }

    .start-trade-date-block {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      width: fit-content;
      font-size: 0.8em;
      text-align: initial;
      color: c.$deepest-blue-8;

      .date-options-block {
        margin-bottom: 10px;
      }

      label {
        margin-top: 5px;
        margin-left: 1em;
      }

      button {
        height: 40px;
        width: fit-content;
        padding: 0 28px;
        border: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: black;
        opacity: .8;
        transition: 0.3s ease-in-out;
        background-color: c.$accent-color;
        letter-spacing: 1px;

        &:disabled {
          background-color: lightgrey;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .step-name {
      @include fs-16;

      .current-step-name {
        font-weight: bold;
        display: flex;

        .account-setup-wrapper {
          padding: 0;
          margin-left: 1em;
          color: c.$accent-color-text;

          .spinner {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }

    .step-status {
      line-height: 1;
      font-size: 0.8em;
      font-weight: bold;

      span {
        color: c.$semi-grey-text-color;
      }
    }

    .step-text,
    .step-text2 {
      @include fs-14;
      text-align: initial;
      color: c.$deepest-blue-8;

      .bold {
        font-weight: bold;
        color: c.$deepest-blue-8;
      }

      a {
        color: c.$accent-color;
        text-decoration: underline;
      }

      .market-data-instructions {
        text-decoration: underline;
        cursor: pointer;
        color: c.$accent-color;
        text-decoration: underline;
      }

      .wire-instruction-button {
        border: none;
        padding: 0;
        background-color: transparent;
        color: c.$accent-color;
        text-decoration: underline;
      }
    }

    .step-text2 {
      padding: .5em;
      margin: .5em;
      border-radius: 5px;
      color: c.$yellow-vivid-6;
      background-image: linear-gradient(to right,
          c.$deepest-blue-4 50%,
          transparent 50%),
        linear-gradient(to right, c.$deepest-blue-4 50%, transparent 50%),
        linear-gradient(to bottom, c.$deepest-blue-4 50%, transparent 50%),
        linear-gradient(to bottom, c.$deepest-blue-4 50%, transparent 50%);
      background-position: left top, left bottom, left top, right top;
      background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
      background-size: 17px 1.5px, 17px 1.5px, 1.5px 17px, 1.5px 17px;
    }

    .v-step-line-wrapper {
      position: absolute;
      top: 6px;
      left: 0;
      height: 100%;

      .short-line {
        width: 4px;
        height: inherit;
        position: absolute;
        left: 15.5px;
        top: 2px;
      }
    }
  }

  .first-step-wrapper {
    min-height: 70px;
  }

  .last-step-wrapper {
    min-height: 60px;
  }
}

.stepper-from-setup-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 1em 8vw 3em 8vw;
}

.left-short-line {
  position: absolute;
  bottom: 5px;
  left: 27px;
  width: 50%;
  height: 3px;
}

.right-short-line {
  position: absolute;
  bottom: 5px;
  left: -55%;
  width: 50%;
  height: 3px;
}

.not-done-step-background {
  background-color: c.$accent-grey-color;
}

.not-done-step-color {
  color: c.$deepest-blue-8;
}

.done-step-background {
  background-color: c.$accent-color;
}

.done-step-color {
  color: c.$accent-color;
}

.rejected-step-background {
  background-color: c.$error-light-red-color;
}

.rejected-step-color {
  color: c.$error-light-red-color;
}

.step-circle {
  position: relative;
  width: 35px;
  height: 35px;
  border: 10px solid c.$deepest-blue-2;
  border-radius: 50%;
  z-index: 2;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90%;
    width: 90%;
  }
}